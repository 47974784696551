var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.data.vip
    ? _c(
        "div",
        [
          _vm._v(_vm._s(_vm.data.vip.englishName)),
          _vm.data.status === 2
            ? [
                _c(
                  "span",
                  {
                    staticClass: "badge badge-pill badge-info",
                  },
                  [_vm._v("Problem Demo Class")]
                ),
              ]
            : _vm.data.status === 3
            ? [
                _c(
                  "span",
                  {
                    staticClass: "badge badge-pill badge-info",
                  },
                  [_vm._v("Demo Paid")]
                ),
              ]
            : [
                _vm.data.vip.vipStatus === 1 &&
                _vm.isNotBefore(_vm.data.title) &&
                _vm.data.vip.analysisCreatedAt
                  ? [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn",
                          class: _vm.onCheckAnalysisTime(
                            _vm.data.vip.classTime,
                            _vm.data.vip.analysisCreatedAt
                          ),
                          attrs: {
                            to: {
                              name: "center-view-analysis",
                              query: {
                                caId: _vm.data.vip.caId,
                                vipId: _vm.data.vip.vipId,
                              },
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: "chart-bar",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("br"),
                      _c("span", [
                        _vm._v(_vm._s(_vm.data.vip.analysisCreatedAt)),
                      ]),
                    ]
                  : _vm._e(),
                _vm.data.vip.vipStatus === 0
                  ? _c(
                      "span",
                      {
                        staticClass: "badge badge-pill badge-danger",
                      },
                      [_vm._v("No show")]
                    )
                  : _vm._e(),
                _vm.data.vip.vipStatus === 2
                  ? _c(
                      "span",
                      {
                        staticClass: "badge badge-pill badge-warning",
                      },
                      [_vm._v("Problem")]
                    )
                  : _vm._e(),
                _vm.data.vip.vipStatus === 3
                  ? _c(
                      "span",
                      {
                        staticClass: "badge badge-pill badge-primary",
                      },
                      [_vm._v("Returned")]
                    )
                  : _vm._e(),
                _vm.data.vip.vipStatus === 4
                  ? _c(
                      "span",
                      {
                        staticClass: "badge badge-pill badge-secondary",
                      },
                      [_vm._v("Cancelled")]
                    )
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }