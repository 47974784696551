var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("good-table", {
    attrs: {
      mode: "remote",
      columns: _vm.fields,
      rows: _vm.rows,
      totalRows: _vm.totalRows,
      "sort-options": {
        enabled: true,
        multipleColumns: false,
      },
      "pagination-options": _vm.options,
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPerPageChange,
      "on-sort-change": _vm.onSortChange,
    },
    scopedSlots: _vm._u([
      {
        key: "table-row",
        fn: function (_ref) {
          var column = _ref.column,
            row = _ref.row
          return [
            column.field === "title" ? [_vm._v(_vm._s(row.title))] : _vm._e(),
            column.field === "classType"
              ? [
                  row.classType === 5
                    ? [
                        _vm._v(_vm._s(_vm.$t(`class_type_${row.classType}`))),
                        _c(
                          "span",
                          {
                            staticClass: "badge badge-danger",
                          },
                          [_vm._v("10")]
                        ),
                      ]
                    : row.classType === 3
                    ? [
                        _vm._v(_vm._s(_vm.$t(`class_type_${row.classType}`))),
                        _c(
                          "span",
                          {
                            staticClass: "badge badge-warning",
                          },
                          [_vm._v("25")]
                        ),
                      ]
                    : [_vm._v(_vm._s(_vm.$t(`class_type_${row.classType}`)))],
                ]
              : _vm._e(),
            column.field === "status"
              ? [
                  row.status === 0 ? [_vm._v("No Show")] : _vm._e(),
                  row.status === 1 ? [_vm._v("Show")] : _vm._e(),
                  row.status === 2 ? [_vm._v("Late")] : _vm._e(),
                  row.status === 3 ? [_vm._v("Tardy")] : _vm._e(),
                  row.status === 4 ? [_vm._v("Problem")] : _vm._e(),
                ]
              : _vm._e(),
            column.field === "material"
              ? [_vm._v(_vm._s(row.material.title))]
              : _vm._e(),
            column.field === "vipA"
              ? [
                  row.vips && row.vips[0]
                    ? _c("vip-field", {
                        attrs: {
                          data: Object.assign({}, row, {
                            vip: row.vips[0],
                          }),
                        },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
            column.field === "vipB"
              ? [
                  row.vips && row.vips[1]
                    ? _c("vip-field", {
                        attrs: {
                          data: Object.assign({}, row, {
                            vip: row.vips[1],
                          }),
                        },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
            column.field === "vipC"
              ? [
                  row.vips && row.vips[2]
                    ? _c("vip-field", {
                        attrs: {
                          data: Object.assign({}, row, {
                            vip: row.vips[2],
                          }),
                        },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }