var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.data.vip
    ? _c(
        "v-template",
        [
          _vm.data.vip.vipEvaluation &&
          _vm.data.vip.vipEvaluation.consultantRating
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "center-view-evaluation",
                      params: {
                        evaluationId: _vm.data.vip.vipEvaluation.id,
                      },
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "badge badge-pill badge-primary",
                    },
                    [_vm._v("Rating:")]
                  ),
                  _c(
                    "b-badge",
                    {
                      attrs: {
                        variant: _vm.onReturnListVariant(
                          _vm.data.vip.vipEvaluation.consultantRating
                        ),
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.data.vip.vipEvaluation.consultantRating)
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.onCheckSuggest(_vm.data.vip.vipEvaluation),
                          expression: "onCheckSuggest(data.vip.vipEvaluation)",
                        },
                      ],
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: {
                          icon: "comments",
                          size: "lg",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "badge badge-pill badge-danger",
                  },
                  [_vm._v("No rating")]
                ),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }